import { changeViewWatcherSaga } from './sagas/change-view/watcher';
import { goToGridWatcherSaga } from './sagas/go-to-grid/watcher';
import { goToSemiFinalWatcherSaga } from './sagas/go-to-semi-final/watcher';
import { setPlayerWinWatcherSaga } from './sagas/set-player-win/watcher';
import { updatePlayersWatcherSaga } from './sagas/update-players/watcher';

export const APP_SAGAS = [
  changeViewWatcherSaga,
  goToGridWatcherSaga,
  goToSemiFinalWatcherSaga,
  setPlayerWinWatcherSaga,
  updatePlayersWatcherSaga,
];
export {
  tournamentReducer,
  changeView,
  addPlayer,
  updatePlayers,
} from './reducer';
export {
  tournamentViewSelector,
  tournamentPlayersSelector,
  tournamentGridPlayersSelector,
  tournamentSemiFinalPlayersSelector,
  tournamentFinalPlayersSelector,
  tournamentWinnersPlayerSelector,
  tournamentGridWinPlayersCountSelector,
  tournamentSemiFinalWinPlayersCountSelector,
  tournamentThirdPlacePlayersSelector,
} from './selectors';
export type {
  AddPlayerAction,
  ChangeViewAction,
  SetPlayerWinPayload,
} from './types';
export {
  goToGridActionSaga,
  goToSemiFinalActionSaga,
  setPlayerWinActionSaga,
  changeViewActionSaga,
} from './actions';
