import React, {
  memo,
  SyntheticEvent,
  useCallback,
} from 'react';
import { InputEventType } from './types';
import './input.css';

type InputPropsType = {
  id?: string;
  isError?: boolean;
  name: string;
  onChange: (inputEvent: InputEventType) => void;
  placeholder?: string;
  type?: 'text' | 'number';
  value: string;
}

export const Input = memo(({
  id,
  isError,
  name,
  onChange,
  type = 'text',
  value,
  placeholder = '',
}: InputPropsType) => {
  const handleChange = useCallback((event: SyntheticEvent<HTMLInputElement>) => {
    onChange({ event, value: event.currentTarget.value, name})
  }, [onChange, name]);

  return (
    <input
      className={isError ? 'Input Input--error' : 'Input'}
      id={id || name}
      name={name}
      onChange={handleChange}
      placeholder={placeholder}
      type={type}
      value={value}
    />
  );
});