import React, { memo } from 'react';
import { Player } from '../../types';
import './player-list.css';

type Props = {
  players: Array<Player>;
};

export const PlayerList = memo(({ players }: Props) => {
  return (
    <ul className="Players-list">
      {players.map(player => (
        <li className="Players-list__item" key={player.name}>{player.name}</li>
      ))}
    </ul>
  );
})