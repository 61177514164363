import { WINNER_TYPE } from '../../../constants';
import { Player } from '../../../types';

let index = 0;
let count = 0;

export const getSemiFinalGroupIndex = (): number => {
  if (count === 2) {
    count = 0;
    index++;
  }

  count++;
  return index;
}

export const preparePlayersToSemiFinal = (players: Array<Player>): Array<Player> => {
  return players.map(player => {
    if (player.winnerType === WINNER_TYPE.SEMI) {
      return {
        ...player,
        groups: {
          ...player.groups,
          semiFinal: getSemiFinalGroupIndex(),
        }
      }
    }

    return player;
  })
};