import { call, put, select } from 'redux-saga/effects';
import { VIEWS_NAME } from '../../../../constants';
import { changeViewActionSaga, updatePlayersActionSaga } from '../../actions';
import { tournamentPlayersSelector } from '../../selectors';
import { preparePlayersToSemiFinal } from '../../utils';

export function* goToSemiFinalWorkerSaga() {
  const players = yield select(tournamentPlayersSelector);

  try {
    const nextPlayers = yield call(preparePlayersToSemiFinal, players);

    yield put(updatePlayersActionSaga(nextPlayers));
    yield put(changeViewActionSaga(VIEWS_NAME.SEMI_FINAL));
  } catch (error) {
    console.error('=== ERROR in goToSemiFinalWorkerSaga ===', error);
  }
}