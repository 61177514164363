import React from 'react';
import ReactDOM from 'react-dom';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import {
  APP_SAGAS,
  tournamentReducer,
} from './redux/tournament';
import { App } from './app';
import './index.css';

const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
  reducer: combineReducers({
    tournament: tournamentReducer,
  }),
  middleware: [sagaMiddleware],
});

APP_SAGAS.forEach(watcher => {
  // @ts-ignore
  sagaMiddleware.run(watcher);
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
