import { Player } from '../../../types';
import { createUniqNumberGenerator } from '../../../utils/create-uniq-number-generator';

const sortPlayersByOrder = (players: Array<Player>): Array<Player> => {
  return players.sort((a , b) => {
    if (a.orderId > b.orderId) return 1;
    if (a.orderId === b.orderId) return 0;
    return -1;
  });
};

const updatePlayersOrderId = (players: Array<Player>): Array<Player> => {
  const getUniqNumber = createUniqNumberGenerator(players.length);

  return players.map(player => ({
    ...player,
    name: player.name,
    orderId: getUniqNumber(),
  }));
};

const getGroupedPlayers = (players: Array<Player>): Array<[Player, Player]> => {
  let count = 0;
  let index = 0;

  return players.reduce((acc: Array<[Player, Player]>, player) => {
    const nextPlayer = {
      ...player,
      groups: {
        ...player.groups,
        grid: index,
      }
    };

    acc[index] = acc[index]
      ? [acc[index][0], nextPlayer]
      : [nextPlayer, nextPlayer];

    count++;

    if (count === 2) {
      count = 0;
      index++;
    }

    return acc;
  }, []);
}

export const preparePlayersToGrid = (players: Array<Player>): Array<Player> => {
  const playersWithUpdatedOrder = updatePlayersOrderId(players);
  const sortingPlayers = sortPlayersByOrder(playersWithUpdatedOrder);
  const groupedPlayers = getGroupedPlayers(sortingPlayers);

  return groupedPlayers.flat();
};