export const LS_KEYS = {
  GROUPS: 'groups',
  PLAYERS: 'players',
  VIEW: 'viewName',
} as const;

export const VIEWS_NAME = {
  HOME: 'HOME',
  GRID: 'GRID',
  SEMI_FINAL: 'SEMI_FINAL',
  THIRD_PLACE: 'THIRD_PLACE',
  FINAL: 'FINAL',
  WINNER:'WINNER',
} as const;

export const WINNER_TYPE = {
  EMPTY: '',
  SEMI: 'semi',
  THIRD_PLACE: 'third place',
  FINAL: 'final',
  FULL: 'full',
} as const;
