import React, {
  FormEvent,
  memo,
  useCallback, useMemo,
  useState,
} from 'react';
import { Player } from '../../types';
import { Input, InputEventType } from '../input';
import { Button } from '../button';
import './form.css';

type FormPropsType = {
  onSubmit: (player: Player) => void;
};

const validation = (value: string): boolean => {
  return Boolean(value) && value.length > 3;
}

export const Form = memo(({ onSubmit }: FormPropsType) => {
  const [value, setValue] = useState('');

  const IsValidValue = useMemo(() => validation(value), [value]);

  const handleChange = useCallback(({ value }: InputEventType) => {
    setValue(value)
  }, []);

  const handleSubmit = useCallback((event: FormEvent) => {
    event.preventDefault();
    onSubmit({
      name: value,
      orderId: 0,
      winnerType: '',
      groups: {
        grid: 0,
        semiFinal: 0
      },
    });
    setValue('');
  }, [onSubmit, value]);

  return (
    <form className="Form" onSubmit={handleSubmit}>
      <div className="Form__input">
        <Input
          onChange={handleChange}
          value={value}
          name="playerName"
          placeholder="Enter player name"
        />
      </div>
      <div className="Form__action">
        <Button
          disabled={!IsValidValue}
          type="submit"
          text="Registration"
        />
      </div>
    </form>
  );
});