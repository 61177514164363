const getRandomNumber = (max: number) => {
  return Math.floor(Math.random() * max);
}

const generateNextUniqNumber = (max: number, prevResult: Array<number>): number => {
  const nextNumber = getRandomNumber(max + 1);

  if (nextNumber === 0 || prevResult.includes(nextNumber)) {
    return generateNextUniqNumber(max, prevResult);
  }

  return nextNumber;
};

export const createUniqNumberGenerator = (max: number): () => number => {
  const prevNumbers: Array<number> = [];

  return () => {
    const nextNumber = generateNextUniqNumber(max, prevNumbers);

    prevNumbers.push(nextNumber);

    return nextNumber;
  };
}