import { put } from 'redux-saga/effects';
import { LS_KEYS } from '../../../../constants';
import { AppState } from '../../../../types';
import { updatePlayers } from '../../reducer';

export function* updatePlayersWorkerSaga(players: AppState['players']) {
  try {
    window.localStorage.setItem(LS_KEYS.PLAYERS, JSON.stringify(players));
    yield put(updatePlayers(players));
  } catch (error) {
    console.error('=== ERROR in updatePlayersWorkerSaga ===', error);
  }
}