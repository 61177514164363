import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { tournamentViewSelector } from '../redux/tournament';
import { VIEWS_NAME } from '../constants';
import { AppStore } from '../types';
import { Home, TournamentGrid, SemiFinalGrid, FinalGrid, Winner, ThirdPlaceGrid } from './components';

type StateProps = {
  viewName: AppStore['tournament']['viewName'];
}
type ViewsSwitchProps = StateProps;

export const ViewsSwitchComponent = ({ viewName }: ViewsSwitchProps) => {
  switch (viewName) {
    case VIEWS_NAME.HOME:
      return <Home />;

    case VIEWS_NAME.GRID:
      return <TournamentGrid />;

    case VIEWS_NAME.SEMI_FINAL:
      return <SemiFinalGrid />;

    case VIEWS_NAME.THIRD_PLACE:
      return <ThirdPlaceGrid />;

    case VIEWS_NAME.FINAL:
      return <FinalGrid/>;

    case VIEWS_NAME.WINNER:
      return <Winner />;

    default:
      return <Fragment />;
  }
};

const mapStateToProps = (state: AppStore) => ({
  viewName: tournamentViewSelector(state),
});

export const ViewsSwitch = connect(mapStateToProps)(ViewsSwitchComponent);