import React, { memo } from 'react';
import { Player } from '../../../types';
import { Button, Table } from '../../../components';
import './grid.css';

type GridProps = {
  nextStepButtonText: string;
  onNextStepButtonClick: () => void;
  onWinnerClick: (name: string) => void;
  players: Array<[Player, Player]>;
  isFullSize?: boolean;
  winnerConditionType: Player['winnerType'];
  shouldShowButton?: boolean;
};

export const GridComponent = ({
  nextStepButtonText,
  onNextStepButtonClick,
  onWinnerClick,
  players,
  isFullSize,
  winnerConditionType,
  shouldShowButton,
}: GridProps) => {
  return (
    <div className="Grid">
      {
        players.map((group, index) => (
          <div
            className={isFullSize ? 'Grid__item Grid__item--full-size' : 'Grid__item'}
            key={index}
          >
            <h4>group {index + 1}</h4>
            <Table
              items={group}
              onWinnerClick={onWinnerClick}
              winnerConditionType={winnerConditionType}
            />
          </div>
        ))
      }
      {shouldShowButton && (
        <div className="Grid__item Grid__item--full-size Grid__item--button">
          <Button text={nextStepButtonText} onClick={onNextStepButtonClick}/>
        </div>
      )}
    </div>
  );
};

export const Grid = memo(GridComponent);