import { put } from 'redux-saga/effects';
import { LS_KEYS } from '../../../../constants';
import { AppState } from '../../../../types';
import { changeView  } from '../../reducer';

export function* changeViewWorkerSaga(nextView: AppState['viewName']) {
  try {
    yield put(changeView(nextView));
    window.localStorage.setItem(LS_KEYS.VIEW, JSON.stringify(nextView));
  } catch (error) {
    console.error('=== ERROR in changeViewWorkerSaga ===', error);
  }
}