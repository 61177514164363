import { Player } from '../../../types';
import { WINNER_TYPE } from '../../../constants';

export const getThirdPlacePlayers = (players: Array<[Player, Player]>): Array<[Player, Player]> => {
   const targetGroup =  players.reduce((acc, group) => {
    const target = group.find(({ winnerType }) => winnerType === WINNER_TYPE.SEMI);

    // @ts-ignore
     acc.push(target);

    return acc;
  }, []);

  // @ts-ignore
  return [targetGroup];
}