import React, { memo } from 'react';
import { ViewsSwitch } from './views-switch';

import './app.css';

const AppComponent = () => {
  return (
      <div className="App">
        <h1 className="App__header">
          Stripchat
          <br/>
          pull tournament
        </h1>
        <ViewsSwitch />
      </div>
  );
}

export const App = memo(AppComponent);