import { createSelector } from 'reselect';
import { AppState, AppStore, Player } from '../../types';
import { WINNER_TYPE } from '../../constants';
import {
  getGridPlayers,
  getSemiFinalPlayers,
  getFinalPlayers,
  getThirdPlacePlayers,
} from './utils';

const tournamentSelector = (state: AppStore): AppState => state.tournament;

export const tournamentViewSelector = createSelector(
  [tournamentSelector],
  ({ viewName }) => viewName
);

export const tournamentPlayersSelector = createSelector(
  [tournamentSelector],
  ({ players }): Array<Player> => players
);

export const tournamentGridPlayersSelector = createSelector(
  [tournamentPlayersSelector],
  (players) => getGridPlayers(players)
);

export const tournamentGridWinPlayersCountSelector = createSelector(
  [tournamentGridPlayersSelector],
  (players) => players.filter((group) => {
    return group.some(({ winnerType }) => winnerType === WINNER_TYPE.SEMI )
  }).length
);

export const tournamentSemiFinalPlayersSelector = createSelector(
  [tournamentPlayersSelector],
  (players) => getSemiFinalPlayers(players)
);

export const tournamentSemiFinalWinPlayersCountSelector = createSelector(
  [tournamentSemiFinalPlayersSelector],
  (players) => players.filter(group =>
    group.some(({ winnerType }) => winnerType === WINNER_TYPE.FINAL)
  ).length
);

export const tournamentThirdPlacePlayersSelector = createSelector(
  [tournamentSemiFinalPlayersSelector],
  (players) => getThirdPlacePlayers(players)
);

export const tournamentFinalPlayersSelector = createSelector(
  [tournamentPlayersSelector],
  (players) => getFinalPlayers(players)
);

export const tournamentWinnersPlayerSelector = createSelector(
  [tournamentPlayersSelector],
  (players: Array<Player>) => players.reduce((acc, player) => {
    if (player.winnerType === WINNER_TYPE.FULL) {
      // @ts-ignore
      acc[0] = player;
    }

    if (player.winnerType === WINNER_TYPE.FINAL) {
      // @ts-ignore
      acc[1] = player;
    }

    if (player.winnerType === WINNER_TYPE.THIRD_PLACE) {
      // @ts-ignore
      acc[2] = player;
    }

    return acc;
  }, [])
);