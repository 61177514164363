import React, { memo, useCallback, useMemo } from 'react';
import { Player } from '../../types';
import { Button } from '../button';
import './table.css';

type TablePropsType = {
  items: [Player, Player];
  onWinnerClick: (name: string) => void;
  winnerConditionType: Player['winnerType'];
}

export const Table = memo(({ items, onWinnerClick, winnerConditionType }: TablePropsType) => {
  const isWinner = useMemo(
    () => items.some(({ winnerType }) => winnerType === winnerConditionType),
    [items, winnerConditionType],
  );

  const handleWinnerClick = useCallback((name: string) => {
    onWinnerClick(name);
  }, []);

  return (
    <table className="Table">
      <thead className="Table__head">
      <tr className="Table__row Table__row--header">
        <th className="Table__cell">order</th>
        <th className="Table__cell">name</th>
        <th className="Table__cell">action</th>
      </tr>
      </thead>
      <tbody className="Table__body">
      {items.map(({ name, orderId, winnerType }) => (
        <tr className="Table__row Table__row--body" key={name}>
          <td className="Table__cell">{orderId}</td>
          <td className="Table__cell">{name}</td>
          <td className="Table__cell">
            {isWinner
              ? (<span>{winnerType === winnerConditionType ? 'WIN' : ''}</span>)
              : (<Button text="Set Win" onClick={() => handleWinnerClick(name)}/>)
            }
          </td>
        </tr>
      ))}
      </tbody>
    </table>
  )
});