import { WINNER_TYPE } from '../../../constants';
import { Player } from '../../../types';

const WINNER_CONDITIONS = [WINNER_TYPE.SEMI, WINNER_TYPE.FINAL];

const getFilteredPlayers = (players: Array<Player>): Array<Player> => {
  // @ts-ignore
  return players.filter(({ winnerType }) => WINNER_CONDITIONS.includes(winnerType));
}

const getGroupedPlayers = (players: Array<Player>): Array<[Player, Player]> => {
  return players.reduce((acc: Array<[Player, Player]>, player) => {
    const index = player.groups.semiFinal;
    const group = acc[index] || [];

    // @ts-ignore
    acc[index] = [...group, player];

    return acc;
  }, [])
}

export const getSemiFinalPlayers = (players: Array<Player>): Array<[Player, Player]> => {
  const filterPlayers = getFilteredPlayers(players);
  const groupedPlayers = getGroupedPlayers(filterPlayers);

  return groupedPlayers.filter(group => group.length === 2);
};