import { select, put } from 'redux-saga/effects';
import { tournamentPlayersSelector } from '../../selectors';
import { updatePlayersActionSaga } from '../../actions';
import { SetPlayerWinPayload } from '../../types';
import {Player} from "../../../../types";

export function* setPlayerWinWorkerSaga({ name, type }: SetPlayerWinPayload) {
  const players = yield select(tournamentPlayersSelector);

  try {
    const nextPlayers = players.map((player: Player) => {
      return player.name === name
        ? { ...player, winnerType: type }
        : player;
    });

    yield put(updatePlayersActionSaga(nextPlayers));
  } catch (error) {
    console.error('=== ERROR in setPlayerWinWorkerSaga ===', error);
  }
}