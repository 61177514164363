import { call, put, select } from 'redux-saga/effects';
import { VIEWS_NAME } from '../../../../constants';
import { changeViewActionSaga, updatePlayersActionSaga } from '../../actions';
import { tournamentPlayersSelector } from '../../selectors';
import { preparePlayersToGrid } from '../../utils';

export function* goToGridWorkerSaga() {
  const players = yield select(tournamentPlayersSelector);

  try {
    const nextPlayers = preparePlayersToGrid(players);

    yield put(updatePlayersActionSaga(nextPlayers))
    yield put(changeViewActionSaga(VIEWS_NAME.GRID))
  } catch (error) {
    console.error('=== ERROR in goToGridWorkerSaga ===', error);
  }
}