import React, {useCallback} from 'react';
import { connect } from 'react-redux';
import { AppStore, Player } from '../../../types';
import { WINNER_TYPE } from '../../../constants';
import {
  tournamentGridPlayersSelector,
  tournamentGridWinPlayersCountSelector,
  goToSemiFinalActionSaga,
  setPlayerWinActionSaga,
  SetPlayerWinPayload,
} from '../../../redux/tournament';
import { Grid } from '../grid';

type MapStateToProps = {
  players: Array<[Player, Player]>;
  winCount: number;
};

type MapDispatchToProps = {
  goToSemiFinal: () => void;
  setPlayerWin: (params: SetPlayerWinPayload) => void;
};

type TournamentGridProps = MapStateToProps & MapDispatchToProps;

export const TournamentGridComponent = ({
  players,
  goToSemiFinal,
  setPlayerWin,
  winCount,
}: TournamentGridProps) => {
  const handleGoToNextView = useCallback(() => {
    goToSemiFinal();
  }, [goToSemiFinal]);

  const handleWinnerClick = useCallback((name: string) => {
    setPlayerWin({ name, type: WINNER_TYPE.SEMI });
  }, [setPlayerWin]);

  return (
    <Grid
      nextStepButtonText="Go to semi-final"
      onNextStepButtonClick={handleGoToNextView}
      onWinnerClick={handleWinnerClick}
      players={players}
      winnerConditionType={WINNER_TYPE.SEMI}
      shouldShowButton={winCount === 4}
    />
  );
};

const mapStateToProps = (state: AppStore): MapStateToProps => ({
  players: tournamentGridPlayersSelector(state),
  winCount: tournamentGridWinPlayersCountSelector(state),
});
const mapDispatchToProps: MapDispatchToProps = {
  goToSemiFinal: goToSemiFinalActionSaga,
  setPlayerWin: setPlayerWinActionSaga,
};

export const TournamentGrid = connect(mapStateToProps, mapDispatchToProps)(TournamentGridComponent);