import React, { FC } from 'react';
import { connect } from 'react-redux';
import { tournamentWinnersPlayerSelector } from '../../../redux/tournament';
import { AppStore, Player } from '../../../types';

type MapStateToProps = {
  winners: Array<Player>;
};

type WinnerProps = MapStateToProps;

export const WinnerComponent: FC<WinnerProps> = ({ winners }) => {
  return (
    <div>
      <h2>Winners!!!</h2>
      <ol>
        {winners.map(winner => (
          <li>{winner.name}</li>
        ))}
      </ol>
    </div>
  );
};

const mapStateToProps = (state: AppStore): MapStateToProps => ({
  winners: tournamentWinnersPlayerSelector(state)!,
});

export const Winner = connect(mapStateToProps)(WinnerComponent);