import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import {
  tournamentThirdPlacePlayersSelector,
  setPlayerWinActionSaga,
  SetPlayerWinPayload,
  changeView,
} from '../../../redux/tournament';
import { AppStore, Player } from '../../../types';
import { WINNER_TYPE, VIEWS_NAME } from '../../../constants';
import { Grid } from '../grid';

type MapStateToProps = {
  players: Array<[Player, Player]>;
};

type MapDispatchToProps = {
  changeView: (viewName: AppStore['tournament']['viewName']) => void;
  setPlayerWin: (params: SetPlayerWinPayload) => void,
};

type ThirdPlaceGridProps = MapStateToProps & MapDispatchToProps;

export const ThirdPlaceGridComponent = ({ changeView, setPlayerWin, players }: ThirdPlaceGridProps) => {
  const handleWinnerClickClick = useCallback((name: string) => {
    changeView(VIEWS_NAME.FINAL);
    setPlayerWin({ name, type: WINNER_TYPE.THIRD_PLACE })
  }, [changeView, setPlayerWin]);

  return (
    <Grid
      nextStepButtonText="Go to final"
      onNextStepButtonClick={() => false}
      onWinnerClick={handleWinnerClickClick}
      players={players}
      winnerConditionType={WINNER_TYPE.THIRD_PLACE}
      isFullSize
    />
  );
};


const mapStateToProps = (state: AppStore): MapStateToProps => ({
  players: tournamentThirdPlacePlayersSelector(state),
});

const mapDispatchToProps: MapDispatchToProps = {
  changeView,
  setPlayerWin: setPlayerWinActionSaga,
};

export const ThirdPlaceGrid = connect(mapStateToProps, mapDispatchToProps)(ThirdPlaceGridComponent);