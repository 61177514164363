import { AppState } from '../../../types';
import { LS_KEYS, VIEWS_NAME } from '../../../constants';

// const PLAYERS_NAME = ['@aplak69', '@kchapsis', '@esh', '@obivan', '@zulgabis', '@miron', '@zyablik', ' @Artemelka'];
//
// const createPlayers = (names: Array<string>): AppState['players'] => {
//   return names.map(name => ({
//     name,
//     orderId: 0,
//     winnerType: '',
//     groups: {
//       grid: 0,
//       semiFinal: 0,
//     }
//   }));
// }

const DEFAULT_STATE: AppState = {
  players: [], // createPlayers(PLAYERS_NAME),
  viewName: VIEWS_NAME.HOME,
};

export const getInitialAppState = (): AppState => {
  const playersInLSJson = window.localStorage.getItem(LS_KEYS.PLAYERS);
  const viewInLSJson = window.localStorage.getItem(LS_KEYS.VIEW);

  try {
    const players = playersInLSJson
      ? JSON.parse(playersInLSJson)
      : DEFAULT_STATE.players;

    const viewName = viewInLSJson
      ? JSON.parse(viewInLSJson)
      : DEFAULT_STATE.viewName;

    return {
      players,
      viewName,
    };
  } catch (error) {
    console.error('ERROR: JSON.parse in getInitialAppState', error);

    return DEFAULT_STATE;
  }
};