import { createSlice } from '@reduxjs/toolkit';
import { AppState } from '../../types';
import { getInitialAppState } from './utils';
import { TournamentStateCases } from './types';

const tournamentReducerSlice = createSlice<AppState, TournamentStateCases>({
  name: 'tournament',
  initialState: getInitialAppState(),
  reducers: {
    addPlayer: (state, { payload }) => {
      state.players.push(payload);
    },
    changeView: (state, { payload }) => {
      state.viewName = payload;
    },
    updatePlayers: (state, { payload }) => {
      state.players = payload;
    }
  }
});

export const tournamentReducer = tournamentReducerSlice.reducer;
export const {
  addPlayer,
  changeView,
  updatePlayers,
} = tournamentReducerSlice.actions;