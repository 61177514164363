import { Player } from '../../../types';

export const getGridPlayers = (players: Array<Player>): Array<[Player, Player]> => {
  return players.reduce((acc: Array<[Player, Player]>, player) => {
    const index = player.groups.grid;
    const group = acc[index] || [];

    // @ts-ignore
    acc[index] = [...group, player];

    return acc;
  }, []);
};