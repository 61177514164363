import React, { FC, useCallback } from 'react';
import { connect } from 'react-redux';
import {
  goToGridActionSaga,
  tournamentPlayersSelector,
  addPlayer,
} from '../../../redux/tournament';
import { Button, Form, PlayerList } from '../../../components';
import { AppStore, Player } from '../../../types';
import './home.css';

type MapDispatchToProps = {
  goToGrid: () => void;
  addPlayer: (player: Player) => void;
}

type StateProps = {
  players: Array<Player>;
};

type Props = StateProps & MapDispatchToProps;

export const HomeComponent: FC<Props> = ({ goToGrid, addPlayer, players }) => {
  const hasPlayers = Boolean(players.length);
  const hasAllPlayers = players.length === 8;
  const isValidPlayersQuantity = !Boolean(players.length % 2);
  const shouldShowButton = hasPlayers && hasAllPlayers;

  const handleButtonClick = useCallback(() => {
    goToGrid();
  }, [goToGrid]);

  const handleAddPlayer = useCallback((player: Player) => {
    addPlayer(player);
  }, [addPlayer])

  return (
    <div className="Home">
      <div className="Home__grid">
        <div className="Home__Left">
          <h3>Players count: {players.length}</h3>
          {!isValidPlayersQuantity && (
            <p className="Home__warning">* must be a multiple of 2</p>
          )}
          <div className="Home__list">
            {players.length
              ? <PlayerList players={players}/>
              : <span>Please add players</span>
            }
          </div>
        </div>
        <div className="Home__right">
          {!shouldShowButton && <Form onSubmit={handleAddPlayer}/>}
        </div>
      </div>
      {shouldShowButton && (
        <div className="Home__action">
          <Button text="Go to tournament grid" onClick={handleButtonClick}/>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: AppStore): StateProps => ({
  players: tournamentPlayersSelector(state)
});

const mapDispatchToProps = {
  addPlayer,
  goToGrid: goToGridActionSaga,
};

export const Home = connect(mapStateToProps, mapDispatchToProps)(HomeComponent);