import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import {
  tournamentSemiFinalPlayersSelector,
  tournamentSemiFinalWinPlayersCountSelector,
  setPlayerWinActionSaga,
  SetPlayerWinPayload,
  changeView,
} from '../../../redux/tournament';
import { AppStore, Player } from '../../../types';
import { WINNER_TYPE, VIEWS_NAME } from '../../../constants';
import { Grid } from '../grid';

type MapStateToProps = {
  players: Array<[Player, Player]>;
  winCount: number;
};

type MapDispatchToProps = {
  changeView: (viewName: AppStore['tournament']['viewName']) => void;
  setPlayerWin: (params: SetPlayerWinPayload) => void,
};

type SemiFinalGridProps = MapStateToProps & MapDispatchToProps;

export const SemiFinalGridComponent = ({ changeView, players, setPlayerWin, winCount }: SemiFinalGridProps) => {
  const handleNextViewClick = useCallback(() => {
    changeView(VIEWS_NAME.THIRD_PLACE);
  }, [changeView]);

  const handleWinnerClickClick = useCallback((name: string) => {
    setPlayerWin({ name, type: WINNER_TYPE.FINAL })
  }, [setPlayerWin]);

  return (
    <Grid
      nextStepButtonText="Go to third place"
      onNextStepButtonClick={handleNextViewClick}
      onWinnerClick={handleWinnerClickClick}
      players={players}
      winnerConditionType={WINNER_TYPE.FINAL}
      shouldShowButton={winCount === 2}
    />
  );
};


const mapStateToProps = (state: AppStore): MapStateToProps => ({
  players: tournamentSemiFinalPlayersSelector(state),
  winCount: tournamentSemiFinalWinPlayersCountSelector(state),
});

const mapDispatchToProps: MapDispatchToProps = {
  changeView,
  setPlayerWin: setPlayerWinActionSaga,
};

export const SemiFinalGrid = connect(mapStateToProps, mapDispatchToProps)(SemiFinalGridComponent);