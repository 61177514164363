import { Action } from '@reduxjs/toolkit';
import { AppState } from '../../types';
import { SetPlayerWinPayload } from './types';

export const GO_TO_GRID_ACTION_SAGA = 'GO_TO_GRID_ACTION_SAGA';
export const goToGridActionSaga = (): Action<typeof GO_TO_GRID_ACTION_SAGA> => ({
  type: GO_TO_GRID_ACTION_SAGA
});

export const CHANGE_VIEW_ACTION_SAGA = 'CHANGE_VIEW_ACTION_SAGA';
export const changeViewActionSaga = (viewName: AppState['viewName']) => ({
  type: CHANGE_VIEW_ACTION_SAGA,
  payload: viewName,
});

export const UPDATE_PLAYERS_ACTION_SAGA = 'UPDATE_PLAYERS_ACTION_SAGA';
export const updatePlayersActionSaga = (players: AppState['players']) => ({
  type: UPDATE_PLAYERS_ACTION_SAGA,
  payload: players,
});

export const GO_TO_SEMI_FINAL_ACTION_SAGA = 'GO_TO_SEMI_FINAL_ACTION_SAGA';
export const goToSemiFinalActionSaga = () => ({
  type: GO_TO_SEMI_FINAL_ACTION_SAGA,
});

export const SET_PLAYER_WIN_ACTION_SAGA = 'SET_PLAYER_WIN_ACTION_SAGA';
export const setPlayerWinActionSaga = (payload: SetPlayerWinPayload) => ({
  type: SET_PLAYER_WIN_ACTION_SAGA,
  payload,
});