import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { WINNER_TYPE, VIEWS_NAME } from '../../../constants';
import { AppStore, Player } from '../../../types';
import {
  tournamentFinalPlayersSelector,
  setPlayerWinActionSaga,
  SetPlayerWinPayload,
  changeViewActionSaga,
} from '../../../redux/tournament';
import { Grid } from '../grid';

type MapStateToProps = {
  players: Array<[Player, Player]>;
};

type MapDispatchToProps = {
  setPlayerWin: (params: SetPlayerWinPayload) => void;
  changeView: (name: AppStore['tournament']['viewName']) => void;
};

type FinalGridProps = MapStateToProps & MapDispatchToProps;

export const FinalGridComponent = ({ changeView, players, setPlayerWin }: FinalGridProps) => {
  const handleWinnerClick = useCallback((name: string) => {
    changeView(VIEWS_NAME.WINNER);
    setPlayerWin({ name, type: WINNER_TYPE.FULL });
  }, [changeView, setPlayerWin]);

  return (
    <Grid
      isFullSize
      nextStepButtonText="CLOSE"
      onNextStepButtonClick={() => false}
      onWinnerClick={handleWinnerClick}
      players={players}
      winnerConditionType={WINNER_TYPE.FULL}
    />
  );
};


const mapStateToProps = (state: AppStore): MapStateToProps => ({
  players: tournamentFinalPlayersSelector(state),
});
const mapDispatchToProps: MapDispatchToProps = {
  setPlayerWin: setPlayerWinActionSaga,
  changeView: changeViewActionSaga,
};

export const FinalGrid = connect(mapStateToProps, mapDispatchToProps)(FinalGridComponent);